import { Link } from 'react-router-dom';
import './style.scss';
import Slider from '../Slider';
import mda from '../assets/bandeau-mda-oleron.jpg';
import ant from '../assets/logo/antennelogo.png';
import tv from '../assets/logo/tvlogo.png';
import electro from '../assets/logo/electrologo.png';
import literie from '../assets/logo/literielogo.png';
import cuisine from '../assets/logo/cuisinelogo.png';
import reparation from '../assets/logo/reparationlogo.png';
import stars from '../assets/etoiles.jpg';

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <div className="home__bloc--title"> 
          <h1 className="home__title"><img className="home__title--img" src={mda} alt="" /></h1>
          <h3 className="home__title--h3">Besoin d'un Lave linge, sèche linge, réfrigérateur, congélateur ou tout simplement d'un appareil ménager. 
          Envie d'un téléviseur ou d'installer une antenne. 
          Besoin d'une nouvelle literie ou d'une nouvelle cuisine ?
          Ou vous avez besoin d'un dépannage Antenne, électroménager ou vous souhaitez simplement commander une pièce détachée pour réparer votre appareil vous même ?</h3>
          <button className="home__btn"> 
            <Link to="/contact">Contactez-nous !</Link>
          </button>
        </div>
      </div>
      <div className="home__bloc--text">
        <h1 className="home__text--h1">Chez MDA OLERON</h1>
        <p className="home__text">Nous sommes là pour vous accompagner dans vos choix. Une équipe de 3 techniciens, 1 cuisiniste et 3 vendeurs sont à votre disposition afin de répondre à vos questions et vous apporter la meilleure solution à votre projet.
        Chez MDA, nous travaillons avec des produits de grandes marques en arrivages permanents, nous bénéficions de produits en arrivages directs d'usine et nous pouvons vous garantir le meilleur rapport qualité prix mais sans oublier le service. 
        Chez nous vous avez deux options, soit vous emportez votre produit, soit vous faites appel à nos équipes techniques afin d'assurer le service. 
        Nous assurons la livraison, l'installation, la formation produit et la reprise des emballages et des anciens appareils.
        En cas de panne, là encore nous sommes présents, nos équipes techniques interviennent à domicile durant toute la période de garantie mais bien au delà également. 
        Faites le choix d'un appareil durable afin de prolonger la durée de vie de votre produit car tous les appareils ne sont pas répérables aux mêmes conditions.
        Nous intervenons sur toute l'île d'Oléron en Charente Maritime.</p>
      </div>
      <Slider /> 
      <div className="home__prestations">
        <h3 className="home__text--h3">Nos Prestations</h3>
        <ul className="home__prestations--lists">
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={ant} alt="" />
            <p className="home__prestations--text">Antenne</p>
          </Link>
          </li>
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={tv} alt="" />
            <p className="home__prestations--text">TV, Image & Son</p>
          </Link>
          </li>
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={electro} alt="" />
            <p className="home__prestations--text">Electroménager</p>
          </Link>
          </li>
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={literie} alt="" />
            <p className="home__prestations--text">Literie</p>
          </Link>
          </li>
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={cuisine} alt="" />
            <p className="home__prestations--text">Cuisine</p>
          </Link>
          </li>
          <li className="home__prestations--list">
          <Link to="/prestations">
            <img className="home__prestations--img" src={reparation} alt="" />
            <p className="home__prestations--text">Dépannage & Réparation</p>
          </Link>
          </li> 
        </ul>
      </div>
      <h2 className="home__text--h2">Avis clients</h2> 
      <div className="home__bloc--list">
          <ul className="home__lists">
            <li className="home__list">
              <h3 className="home__list--title">Jacqueline</h3>
              <img className="home__list--img" src={stars} alt="" />
              <p className="home__list--text">Excellent tant dans l'accueil  que dans l'intervention des techniciens, merci pour ce professionnalisme et écoute sérieuse.</p>
            </li>
            <li className="home__list">
              <h3 className="home__list--title">Régine</h3>
              <img className="home__list--img" src={stars} alt="" />
              <p className="home__list--text">Accueil parfait d'un bout à l'autre. Conseil avisé, installation et suivi au top. On y a acheté notre (très belle) cuisine et tout l'électroménager. Prochaine étape la literie. Un grand merci!!!</p>
            </li>
            <li className="home__list">
              <h3 className="home__list--title">Michael</h3>
              <img className="home__list--img" src={stars} alt="" />
              <p className="home__list--text">Super accueil, un très bon rapport qualité prix, service après vente très réactif et honnête.</p>
            </li>
          </ul>
        </div>
    </div>
  );
}

export default Home;
