import machine from '../assets/machine1.jpg';
import cuisine from '../assets/cuisine1.jpg';
import antenne from '../assets/antenne.jpg';
import matelas from '../assets/matelas1.jpg';
import reparation from '../assets/reparation1.jpg';
import tv from '../assets/tv1.jpg';
import './style.scss';

function Prestation() {
  return (
    <div className="prestation">
      <h1 className="prestation__title">Prestations</h1>
      <div className="prestation__card">
        <div className="card">
          <h4 className="card__title">électroménager</h4>
          <img className="card__img" src={machine} alt="" />
          <div className="card__container">
            <p className="card__text">Vous recherchez un lave linge, un lave vaisselle ou tout autre produit électroménager sur l'île d'Oléron, vous êtes au bon endroit. 
            Le magasin MDA OLERON travaille avec toutes les grandes marques. 
            Vous aurez toujours un produit répondant à vos attentes et à votre budget. 
            Nous sommes en mesure de vous proposer des solutions de garanties complémentaires ainsi que des moyens de financement sans frais afin de ne pas vous contraindre dans vos dépenses.</p>
            <p className="card__text">Avec un magasin de près de 400m² sur Saint Pierre d'Oléron, nous sommes la référence de l'île d'Oléron, nous offrons le plus grand choix d'appareils électroménagers. 
            Nous sommes en mesure de vous proposer près de 20 000 références d'articles. 
            De la machine à café au lave vaisselle connecté, vous trouverez votre bonheur chez nous. 
            Retrouvez déjà quelques unes de nos références sur notre site internet (prix-discount) et n'hésitez pas à passer commande en ligne, nos équipes magasin traiteront votre demande et nos équipes techniques interviendront chez vous.
            Nous sommes un commerce de proximité et nous serons toujours présents pour vous accompagner. Besoin d'un conseil avant votre achat, contactez le magasin et demander des précisions à nos vendeurs spécialisés.</p>
          </div>
        </div>
        <div className="card">
          <h4  className="card__title">TV, Image & Son</h4>
          <img className="card__img" src={tv} alt="" />
          <div className="card__container">
            <p className="card__text">Vous recherchez un téléviseur LED, QLED ou OLED, ou vous souhaitez tout simplement connaître la différence.
            Venez découvrir par vous même les nouvelles technologies employées pour la conception de vos nouveaux téléviseurs.
            Venez au magasin pour rencontrer nos équipes de spécialistes, ils se feront un plaisir de vous renseigner. 
            Les technologies  et les normes évoluent sans cesse et il est parfois difficile de s'y retrouver. 
            Chez MDA SAINT PIERRE D'OLERON, nous sommes là pour vous conseiller et vous guider dans vos choix en fonction de vos attentes, de vos contraintes d'espace ou encore de vos contraintes budgétaires. 
            Nous avons le plus grand choix de produit, vous pouvez consulter une partie de nos produits sur notre catalogue en ligne (prix-discount).</p>
            <p className="card__text">Vous ne souhaitez pas commander en ligne et vous souhaitez avoir des informations complémantaires, appelez le magasin au 05 46 47 00 25 et demandez nous conseil.</p>
            <p className="card__text">Vous avez déjà votre TV mais le système de son ne vous convient pas. Demandez une prestation, nous pouvons intervenir à domicile et vous conseiller sur le meilleur système audio qui vous conviendra. 
            De la barre de son à 360 degrés avec caisson de basse intégré au casque audio en passant par le coussin audio, nous avons plusieurs solutions à même de vous apporter le meilleur confort possible.</p>
          </div>
        </div>
        <div className="card">
          <h4 className="card__title">Literie</h4>
          <img className="card__img" src={matelas} alt="" />
          <div className="card__container">     
            <p className="card__text">Que vous recherchiez un matelas, un sommier, un cadre à latte, une literie motorisée un surmatelas ou tout simplement du linge de maison, n'hésitez à venir découvrir notre espace literie. 
            80m² dédié à l'univers de la literie. Des matelas et sommiers de grandes marques, en fabrication FRANCAISE, en arrivage direct usine. 
            Nous avons régulièrement des ensembles sommier+matelas à prix très abordables. 
            Le petit plus, nous livrons gratuitement à domicile et nous reprenons les anciennes literie* (voir conditions au magasin)</p>
            <p className="card__text">N'hésitez pas à demander conseil à nos deux spécialistes literie (Grégory et Clément) qui sauront vous conseiller le meilleur choix en fonction de vos attentes, de votre morphologie et tout en respectant votre budget.</p>
            <p className="card__text">Venez prendre le temps d'essayer nos produits, l'espace literie est à l'étage du magasin (accessible par ascensseur ou escalier) et vous serez à l'écart de la clientèle pour prendre le temps car une literie ne s'achète pas sur un coût de tête. 
            il faut prendre le temps et être sûr de son achat, vous allez y passer toutes vos nuits et rien de telle qu'une bonne literie pour se lever du bon pied et attaquer une belle journée.</p>
          </div>
        </div>
        <div className="card">
          <h4 className="card__title">Cuisine</h4>
          <img className="card__img" src={cuisine} alt="" />
          <div className="card__container"> 
            <p className="card__text">Chez MDA à Saint Pierre d'Oléron en Charente Maritime, nous vous proposons une solution complète de la conception de votre projet à la pose, en passant par l'installation des vos appareils électroménagers.</p>
            <p className="card__text">Qui est mieux placé que nous pour vous conseiller dans le choix de votre cuisine. 
            Nous intervenons chez vous régulièrement pour remplacer vos appareils electroménagers, notre expérience est sans appel.
            Aujourd'hui, nous avons un cuisiniste dédié à votre projet de conception. Il interviendra chez vous pour mesurer l'espace et prendre la mesure de toutes les contraintes (eau, électricité, espace, menuiserie...)
            A la suite de se premier entretien, nous travaillerons sur l'élaboration de vos plans et sur votre devis ; tous ces éléments vous seront remis lors de cet entretien, nous n'avons rien à cacher. 
            Nos produits sont de belle qualité, fabriqué directement en ALLEMAGNE à l'usine BURGER/BAUFORMAT, les meubles sont déjà assemblés d'usine pour vous assurer la meilleure qualité de montage possible et pour limiter de temps de pose et donc le coût global.
            Nous vous livrerons en fonction de vos contraintes et nos poseurs interviennent rapidement afin d'installer l'ensemble.
            Le point fort, en plus de notre activité cuisine, nous sommes ménagiste, nous avons le plus grand choix de produits électroménagers de l'île d'Oléron et surtout, nous assurons le suivi de vos appareils, pas seulement le temps de la garantie comme la plupart des cuisinsistes mais bien sur la durée de vie de l'appareil. 
            Tant que la marque nous fourni les pièces détachées, nous serons en mesure de vous proposer un dépannage, même 10 ans après la pose de votre cuisine !!!
            N'hésitez plus et venez commander votre cuisine dans notre magasin MDA à Saint pierre d'Oléron, Franck se fera une joie de vous accueillir.</p>
          </div>
        </div>
        <div className="card">
          <h4 className="card__title">Antenne</h4>
          <img className="card__img" src={antenne} alt="" />
          <div className="card__container">
            <p className="card__text">Sur l'île d'Oléron, il y a beaucoup de contraintes liées à la réception TV. L'antenne rateau ne fonctionne pas partout car le relais est trop loin et les perturbations sont nombreuses, la fibre est loin de satisfaire tout le monde avec de nombreuses coupures de réseau et un abonnement mensuel à un coût important.</p>
            <p className="card__text">Nous vous proposons l'installation de votre parabole, c'est le meilleur moyen de vous garantir une image de qualité sans abonnement mensuel et vous restez propriétaire de votre matériel. 
            En cas de problème avec votre décodeur, nous effectuons les mises à jour des décodeurs au magasin, sans frais (pour les prouits achétés au magasin) ou nous intervenons à domicile pour vous aider. 
            Julien, notre technicien antenne sera là pour vous conseiller, vous dépanner ou faire évoluer votre matériel pour que vous puissiez recevoir la TV gratuite sans abonnement et avec le moins de contraintes possible.
            Seule chose, pensez à renouveller votre carte satellite tous les 4 ans, chez FRANSAT, nous les avons à disposition au magasin pour 20€ tous les 4 ans. 
            C'est vraiment la solution à moindre coût pour recevoir la TNT sur l'île d'Oléron.
            Demandez nous conseil pour l'installation de votre antenne ou demandez tout simplement votre devis. 
            Vous pouvez nous appeler ou demander votre intervention via notre formulaire de contact, nous vous contacterons dans les plus brefs délais..</p>
          </div>
        </div>
        <div className="card">
          <h4 className="card__title">Dépannage & Réparation</h4>
          <img className="card__img" src={reparation} alt="" />
          <div className="card__container">
            <p className="card__text">Notre magasin est connu depuis plus de 50 ans dans le dépannage des appareils électroménagers. 
            Nos équipes de techniciens interviennent chez vous pour assurer le diagnostic de vos appareils ménagers.
            Que se soit pour réparer votre lave linge, sèche linge, réfrigérateur, cave à vin, réfrigérateur américain, ou tout simplement pour réparer votre aspirateur, nos techniciens vous apporteront le meilleur service possible. 
            Au choix, apportez vos appareils au magasin ou laissez nous intervenir à domicile pour assurer le service. Vous ne serez pas dessus par le savoir faire de nos équipes et par leur bonne humeur. 
            N'hésitez pas à leur poser des questions sur l'entretien de vos appareils, il y a beaucoup d'idées reçues sur l'entretien de des appareils, n'utilisez plus de vinaigre blanc par exemple, un remède de grand mère souvent utilisé il y a plusieurs années mais qui n'est plus adapté à la qualité de nos appareils modernes. 
            N'attendez pas que votre machine tombe en panne, faite venir votre technicien pour l'entretien, comme vous le feriez pour la mécanique de votre voiture...en cas de doute demandez nous conseil.</p>
            <p className="card__text">En tant que dépanneur électroménager, nous avons accès à toutes les grandes marques et nous sommes à même de vous proposer la plupart des pièces détachées de vos appareils. 
            Que vous ayez acheté votre appareil chez nous ou dans un autre réseau, nous n'avons besoin que de la marque et du modèle de votre appareil afin de vous confirmer un tarif et un délais pour la réception de votre pièce. 
            Libre à vous de choisir de l'emporter pour la remonter par vos propres moyens ou de faire appel à nos équipes techniques pour assurer la prestation (sur devis).
            La seule contrainte que nous ayons, nous devons travailler avec la marque, si le produit que vous avez acheté est une sous marque d'un autre réseau, il y a peu de chance que ce dernier soit réparable. 
            Dans tous les cas, n'hésitez pas à nous appeler ou à remplir le formulaire de contact afin de demander votre devis de pièce détachée, nous mettrons tout en oeuvre pour vous répondre dans les meilleurs délais.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prestation;
