import mda from '../assets/logo-mda-oleron.jpg';
import { Link } from "react-router-dom";
import './style.scss';

function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__lists">
        <ul className="footer__links">
          <li className="footer__item">
            <Link to="/" className="footer__link">
              Accueil
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/histoire" className="footer__link">
              Histoire
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/prestations" className="footer__link">
              Nos Prestations
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/contact" className="footer__link">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer__information">
        <h3 className="footer__title">Horaires :</h3>
        <p className="footer__text">Du Mardi au Samedi : 9h30-12h30 15h00-19h00</p>
        <h3 className="footer__title">Adresse :</h3>
        <p className="footer__text">73 Avenue de Bel air, 17310 Saint-Pierre-d'oléron</p>
        <h3 className="footer__title">Téléphone :</h3>
        <p className="footer__text">05.46.47.00.25</p>
      </div>
        <div className="footer__bloc">
        <img className="footer__logo" src={mda} alt="logo" />
        <ul className="footer__logo-items">
          <li className="footer__items" href="/mentions-legales">Mentions Légales</li>
          <li className="footer__items">Politique De Confidentialité</li>
        </ul>
      </div>
      </div>
    </div>
  );
}

export default Footer;
