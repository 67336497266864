import pulsat from '../assets/pulsat.jpg';
import mda from '../assets/mda.jpg';
import interieur1 from '../assets/interieur1.jpg';
import "./style.scss";


function History() {
  return (
    <div className="history">
      <h1 className="history__title">Histoire du Magasin</h1>
      <div className="history__content">
        <img className="history__content--img" src={mda} alt="" />
        <div className="history__content--bloc">
          <p className="history__content--text">MDA est présent sur l'île d'Oléron sous cette enseigne depuis 2019 mais notre magasin ELECTROLERON existe depuis plus de 50 ans.
          La société a été créée en 1965 lors de la création du pont de l'île d'Oléron par son fondateur Monsieur MICHAUD, qui a d'abord travaillé sous son nom et sous enseigne PHILIPS. 
          Il s'agit d'un des plus anciens magasins vendant et dépannant les appareils électroménagers de l'île d'Oléron. </p>
          <p className="history__content--text">Vers 2005, Monsieur et Madame Desse reprennent le magasin après Monsieur Michaud et change l'enseigne pour PULSAT. Electroléron travaillera alors sous enseigne PULSAT jusqu'en 2012. 
          En 2012, le magasin est repris à son tour par la famille May (Jacques et Catherine et leur fils Anthony).
          De 2012 à 2019, Electroleron continuera de travailler sous enseigne PULSAT.</p>
        </div>
      </div>
      <div className="history__content">
        <img className="history__content--img" src={pulsat} alt="" />
        <div className="history__content--bloc">
          <p className="history__content--text">Dès 2015, les clients reconnaissaient l'enseigne PULSAT comme vendant des produits de qualité avec un bon service mais trop cher face à la concurrence. 
          Après de nombreuses remarques de la clientèle et une évolution de la concurrence (notamment internet) nous avons décidé d'opter pour une nouvelle enseigne nous permettant d'obtenir de meilleures conditions d'achat et ainsi répondre à la demande de la clientèle qui souhaitait avoir de meilleurs tarifs. 
          Nous optons donc pour l'enseigne MDA en 2019.</p>
          <p className="history__content--text">Le concept MDA : 2 à 3 vendeurs en magasin, pas de livraison, pas de service après vente en magasin mais des tarifs défiants toute concurrence.
          Nous faisons le choix, à la demande générale de la clientèle de conserver notre activité de service, nous gardons donc l'activité Antenne, réparation d'appareils électroménagers et livraison à domicile. 
          Nous ajoutons également les activités Literie et Cuisine sur mesure.</p>
        </div>
      </div>
      <div className="history__content">
        <img className="history__content--img" src={interieur1} alt="" />
        <div className="history__content--bloc">
          <p className="history__content--text">En 2021, Monsieur et Madame MAY prennent leur retraite et laisse leur fils Anthony MAY reprendre le magasin.
          En 2022, le site internet décrivant l'activité est créé afin de faire connaître nos métiers et nos valeurs sur Internet au plus grand nombre. 
          Fin 2022, notre premier site e-commerce voit le jour, www.prix-discount.fr  les clients peuvent voir une grande partie de nos produits sur le site internet avec la possibilité d'acheter directement sur le site mais avec la possibilité d'emporter le produit du magasin ou de le faire livrer à domicile par les équipes du magasin. 
          Prévision 2023-2024 : Création d'un entrepot de stockage afin d'optimiser les achats, maitriser les volumes et les stocks, offrir plus de choix en magasin et encore de meilleures conditions tarifaires.
          </p>
        </div>
      </div>
    </div>
  );
}

export default History;
