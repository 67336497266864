import { Routes, Route } from 'react-router-dom';
import ScrollToTop from '../Function/SrollToTop';
import Header from '../Header';
import Home from '../Home';
import Prestation from '../Prestation';
import History from '../History';
import Contact from '../Contact';
import Footer from '../Footer';
import '../styles/index.scss'
import './style.scss';

const App = () => (
  <div className="app">
    <Header />
    <ScrollToTop />
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/prestations" element={<Prestation />} />
      <Route path="/histoire" element={<History />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </div>
);

export default App;
