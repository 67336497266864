import { useState } from 'react';
import Nav from './Nav';
import './style.scss';

function Header() {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="header">
      <Nav active={isActive} setIsActive={setIsActive} />
    </div>
  );
}

export default Header;
