/* eslint-disable react/button-has-type */
import { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import mda from '../assets/logo-mda-oleron.jpg';
import './style.scss';

function Nav() {
  const [showLinks, setShowLinks] = useState(false);
  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <nav className={`navbar ${showLinks ? 'show-nav' : ''} `}>
      <div className="navbar__logo">
      <Link to="/">
        <img src={mda} alt="logo" />
      </Link>
      </div>
      <ul className="navbar__links">
        <li className="navbar__item" onClick={handleShowLinks}>
          <NavLink to="/" className="navbar__link">
            Accueil
          </NavLink>
        </li>
        <li className="navbar__item" onClick={handleShowLinks}>
          <NavLink to="/prestations" className="navbar__link">
            Nos Prestations
          </NavLink>
        </li>
        <li className="navbar__item" onClick={handleShowLinks}>
          <NavLink to="/histoire" className="navbar__link">
            Histoire
          </NavLink>
        </li>
        <li className="navbar__item" onClick={handleShowLinks}>
          <NavLink to="/contact" className="navbar__link">
            Contact
          </NavLink>
        </li>
      </ul>
      <button className="navbar__burger" onClick={handleShowLinks}>
        <span className="burger-bar" />
      </button>
    </nav>
  );
}

export default Nav;
