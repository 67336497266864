import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../assets/slider/img1.jpg';
import img2 from '../assets/slider/img2.jpg';
import img3 from '../assets/slider/img3.jpg';
import img4 from '../assets/slider/img4.jpg';
import img5 from '../assets/slider/img5.jpg';
import img6 from '../assets/slider/img6.jpg';
import img7 from '../assets/slider/img7.jpg';
import './style.scss';


function Slider() {
  return (
    <Carousel
    autoplay
    interval={60}
    infiniteLoop
    thumbWidth={120}
    showIndicators={false}
    showStatus={false}
    >
      <div className="overlay">
        <img src={img1} alt="" />
      </div>
      <div className="overlay">
        <img src={img2} alt="" />
      </div>
      <div className="overlay">
        <img src={img3} alt="" />
      </div>
      <div className="overlay">
        <img src={img4} alt="" />
      </div>
      <div className="overlay">
        <img src={img5} alt="" />
      </div>
      <div className="overlay">
        <img src={img6} alt="" />
      </div>
      <div className="overlay">
        <img src={img7} alt="" />
      </div>
    </Carousel>
    );
}

export default Slider;
