import "./style.scss";
import mda from '../assets/mda.jpg';
import facebook from '../assets/facebook.png';

function Contact() {
  return (
    <div class="contact">
      <h1 className="contact_title">Contactez-nous</h1>
      <p className="contact_text">Pour une demande d'intervention ou de renseignements : </p>
      <div className="contact_info">
      <img className="contact_img" src={mda} alt="" />
      <div className="contact_info-text">
        <h2 className="contact_title-info">Sas electroleron</h2>
        <h3 className="contact_subtitle">Horaires</h3>
        <p className="contact_subtitle-text">Du Mardi au Samedi :
        9h30 - 12h30 et 15h00 - 19h00</p>
        <h3 className="contact_subtitle">Adresse</h3>
        <p className="contact_subtitle-text">73 Avenue de Bel-Air, 17310 Saint-Pierre-D'Oléron</p>
        <h3 className="contact_subtitle">Nous Contactez</h3>
        <p className="contact_subtitle-text">Par téléphone : 05.46.47.00.25</p>
        <p className="contact_subtitle-text">Par mail :
        <button className="contact_btn">
          <a href="mailto:mda.oleron@gmail.com">Cliquer ici !</a>
        </button>
        </p>
      </div>
      </div>
      <div className="social">
        <p className="social_text">Suivez nos actualités !</p>
        <ul className="social_list">
          <li className="social_lists">
          <a href="https://fr-fr.facebook.com/MDAElectro" target="_blank" rel="noreferrer">
          <img className="social_img" src={facebook} alt="facebook" />
          </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;
